/*==================== GOOGLE FONTS ====================*/

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,700;0,800;0,900;1,100;1,200;1,300;1,600;1,700;1,800;1,900&display=swap');

/*==================== VARIABLES CSS ====================*/

:root {
    --header-height: 3rem;
    /*========== Colors ==========*/
    /* Change favorite color */
    --hue-color: 122;
    /*Purple 250 - Green 142 - Blue 230 - Pink 340*/
    /* HSL color mode */
    --first-color: hsl(var(--hue-color), 58%, 50%);
    --white: #fff;
    --black: #000;
    --black-blue: #00085C;
    --light: #f8f9fa;
    --maron: #a80;
    --orange: #DD7016;
    --light-hight: #aaa;
    --first-color-second: hsl(var(--hue-color), 69%, 35%);
    --first-color-alt: hsl(var(--hue-color), 57%, 53%);
    --first-color-lighter: hsl(var(--hue-color), 92%, 85%);
    --title-color: hsl(var(--hue-color), 8%, 15%);
    --text-color: hsl(var(--hue-color), 8%, 45%);
    --text-color-light: hsl(var(--hue-color), 8%, 65%);
    --input-color: hsl(var(--hue-color), 70%, 96%);
    --body-color: #FFF;
    /*hsl(var(--hue-color), 60%, 99%);*/
    --container-color: var(--white);
    --scroll-bar-color: hsl(var(--hue-color), 12%, 90%);
    --scroll-thumb-color: hsl(var(--hue-color), 12%, 80%);
    /*========== Font and typography ==========*/
    --body-font: 'Poppins', sans-serif;
    /* .5rem = 8px, 1rem = 16px, 1.5rem = 24px ... */
    --big-font-size: 2rem;
    --h1-font-size: 1.5rem;
    --h2-font-size: 1.25rem;
    --h3-font-size: 1.25rem;
    --normal-font-size: .938rem;
    --small-font-size: .813rem;
    --smaller-font-size: .75rem;
    /*========== Font weight ==========*/
    --font-medium: 500;
    --font-semi-bold: 600;
    /*========== Margenes Bottom ==========*/
    /* .25rem = 4px, .5rem = 8px, .75rem = 12px ... */
    --mb-0-25: 0.25rem;
    --mb-0-5: 0.5rem;
    --mb-0-75: 0.75rem;
    --mb-1: 1rem;
    --mb-1-5: 1.5rem;
    --mb-2: 2rem;
    --mb-2-5: 2.5rem;
    --mb-3: 3rem;
    /*========== z index ==========*/
    --z-tooltip: 10;
    --z-fixed: 100;
    --z-modal: 1000;
}


/* Font size for large devices */

@media screen and (min-width: 968px) {
     :root {
        --big-font-size: 2.5rem;
        --h1-font-size: 2.25rem;
        --h2-font-size: 1.5rem;
        --h3-font-size: 1.25rem;
        --normal-font-size: 1rem;
        --small-font-size: .875rem;
        --smaller-font-size: .813rem;
    }
}


/*========== Variables Dark theme ==========*/

body.dark {
    /* HSL color mode */
    --first-color-second: hsl(var(--hue-color), 30%, 8%);
    --title-color: hsl(var(--hue-color), 8%, 95%);
    --white: #000;
    --black: #fff;
    --black-blue: #eef;
    --light: #456;
    --light-hight: #ddd;
    --text-color: hsl(var(--hue-color), 8%, 75%);
    --input-color: hsl(var(--hue-color), 29%, 16%);
    --body-color: hsl(var(--hue-color), 29%, 12%);
    --container-color: hsl(var(--hue-color), 29%, 16%);
    --scroll-bar-color: hsl(var(--hue-color), 12%, 48%);
    --scroll-thumb-color: hsl(var(--hue-color), 12%, 36%);
}

.hstack {
    border: 0px solid var(--white) !important;
}


/*==================== BASE ====================*/

* {
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;
}

html {
    scroll-behavior: smooth;
    font-family: var(--body-font);
}

body {
    margin: 0 0 var(--header-height) 0;
    font-family: var(--body-font);
    font-size: var(--normal-font-size);
    background-color: var(--body-color);
    color: var(--text-color);
}

html,
body {
    max-width: 100%;
    width: 100%;
    overflow-x: hidden;
    padding: 0px !important;
    margin: 0px;
}

h1,
h2,
h3,
h4 {
    font-weight: var(--font-semi-bold);
}


/*ul {
    list-style: none;
}*/

a {
    text-decoration: none;
}

img {
    max-width: 100%;
    height: auto;
}

.object-fit-cover {
    object-fit: cover;
}

.object-fit-contain {
    object-fit: cover;
}

label {
    color: var(--light-hight);
}

.bg-light- {
    background-color: var(--light);
}

.bg-primary- {
    background-color: var(--first-color) !important;
}

.bg-black-blue- {
    background-color: var(--black-blue) !important;
}

.btn-primary- {
    background-color: var(--first-color);
    color: var(--white);
}

.bg-light-primary- {
    background-color: rgba(87, 224, 121, 0.13);
}

.bg-white- {
    background-color: var(--white);
}

.btn-outline-primary- {
    background-color: var(--first-color);
    border-radius: 5px;
    border: 1px solid var(--white);
    color: var(--white);
}

.btn-outline-white-orange- {
    border-radius: 5px;
    border: 1px solid var(--white);
    color: var(--white);
    padding: 5px 15px;
    font-weight: 700;
    transition: all 0.3s ease-in-out;
}

.btn-outline-white-orange-:hover,
.btn-outline-white-orange-:focus {
    background-color: var(--white);
    border: 1px solid var(--first-color);
    color: var(--orange);
}

.btn-outline-white-primary- {
    border-radius: 5px;
    border: 1px solid var(--white);
    color: var(--white);
    padding: 5px 15px;
    font-weight: 700;
    transition: all 0.3s ease-in-out;
}

.btn-outline-white-primary-:hover,
.btn-outline-white-primary-:focus {
    background-color: var(--white);
    border: 1px solid var(--first-color);
    color: var(--first-color);
}

.text-black- {
    color: var(--black);
}

.text-white- {
    color: var(--white);
}

.text-color- {
    color: var(--text-color);
}

.text-black-blue- {
    color: var(--black-blue) !important;
}

.text-color-light- {
    color: var(--text-color-light);
}

.text-primary- {
    color: var(--first-color);
}

.text-primary-dark- {
    color: var(--first-color-second);
}

.text-black-blue- {
    color: var(--black-blue);
}

.member_link {
    position: absolute;
    bottom: 20px;
    width: 100%;
    left: 10px;
    right: 0px;
}


/*********************** NAV *****************/

.nav_ {
    padding: 15px 30px;
    background-color: var(--white);
    border-bottom: 1px solid rgba(0, 0, 0, 0.16);
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
}

#siteName {
    font-size: var(--h3-font-size);
    text-transform: uppercase;
    font-size: 19px;
    word-spacing: 5px;
    color: var(--first-color);
    font-weight: 550;
}

#siteNameMobile {
    font-size: 18px;
    color: var(--white);
    font-weight: 600;
}

.nav_item {
    text-transform: uppercase;
    font-size: 14px;
    color: #000;
    margin-left: 30px;
}

.nav_link {
    color: var(--text-color);
    font-weight: 550;
    transition: all 0.2s ease-in-out;
    letter-spacing: 0.5px;
}

.nav_link:hover,
.nav_link:focus {
    color: var(--first-color);
}

.nav_i {
    font-size: 18px;
    font-weight: 700;
    color: var(--text-color);
}

.nav_item_mobile {
    margin-top: 20px;
    margin-left: 30px;
}

.nav_link_mobile {
    color: var(--white);
    text-align: center;
    font-size: 16px;
}


/**************** HEADER ***********/

.slide_one {
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("images/slide_one.jpg");
    background-size: cover;
    text-align: center;
    padding: 0px 100px;
}

.slide_two {
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("images/slide_two.jpg");
    background-size: cover;
    text-align: center;
    padding: 0px 100px;
}

.slide_three {
    background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url("images/slide_three.jpg");
    background-size: cover;
    text-align: center;
    padding: 0px 100px;
}

.header {
    height: 500px;
}

.swiper {
    width: 100%;
    background-color: var(--white);
}

.swiper-button-next,
.swiper-button-prev {
    background-color: white;
    background-color: rgba(255, 255, 255, 0.5);
    right: 10px;
    padding: 8px 22px;
    margin: 0 !important;
    color: #000 !important;
    fill: black !important;
    stroke: black !important;
    border-radius: 50%;
    font-weight: 800;
}

.swiper-button-next::after,
.swiper-button-prev::after {
    font-size: 20px !important;
    font-weight: 800 !important;
}

.app_bar {
    padding: 5px 10px;
    background-color: var(--first-color);
}

.header .swiper-slide {
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}


/****************** OUR COMPANY ***************/

.our_company {
    padding: 30px 0px;
}

.our_company_content {
    padding: 30px;
    line-height: 30px;
}

.our_company_content h6 {
    line-height: 30px;
}

.our_company_title {
    font-size: 14px;
    padding: 5px 25px;
    background-color: var(--first-color);
    opacity: 0.9;
}


/****************** HEADER BOTTOM *************/

.header_bottom {
    padding: 0px;
    text-align: center;
    background-color: var(--orange);
}


/****************** ENTREPRISE *****************/

.entreprise {
    background-color: #F6F6F6;
    padding: 50px 0px;
}

.entreprise_dot_list {
    margin-bottom: 25px;
}

.entreprise_dot {
    color: var(--black);
    background-color: var(--white);
    font-size: 13px;
    padding: 10px 20px;
    text-align: center;
    font-weight: var(--font-medium);
    margin-right: 5px;
    transition: all 0.3s;
}

.entreprise_dot.active,
.entreprise_dot:hover,
.entreprise_dot:focus {
    color: var(--white);
    background-color: var(--first-color);
}

.entreprise_dot_detail {
    padding: 35px 50px;
    font-size: 15px;
    color: #000;
    line-height: 25px;
}

.entreprise_image {
    height: 240px;
    object-fit: cover;
    width: 100%;
}


/***************** WORK TOGETHER ***************/

.work_together {
    padding: 0px 0px;
    background-color: var(--first-color);
}


/****************** OUR SERVICE *****************/

.ourservice {
    padding: 50px 0px;
}

.ourservice_item {
    transition: all 0.2s ease-in-out;
}

.ourservice_item .first {
    background-color: var(--white);
    transition: all 0.2s ease-in-out;
    height: 250px;
    box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.1);
}

.ourservice_item .first h6 {
    text-transform: uppercase;
    padding: 0px 20px;
    line-height: 30px;
}

.ourservice_item .first:hover .ourservice_item_img,
.ourservice_item .first:focus .ourservice_item_img {
    display: none;
}

.ourservice_item .first:hover .ourservice_button,
.ourservice_item .first:focus .ourservice_button {
    visibility: visible;
}

.ourservice_button {
    padding: 8px 15px;
    border-radius: 5px;
    visibility: hidden;
    border: none;
    background-color: #25d366;
    color: #FFF;
}

.ourservice_item .first:hover,
.ourservice_item .first:focus {
    box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.15);
}

.ourservice_item_img {
    height: 80px;
    width: 80px;
    object-fit: cover;
    margin: 0px auto;
}

.single_service {
    background-color: #FFF5E5;
    margin: 30px 0px;
}

.single_service img {
    width: 100%;
    object-fit: cover;
}

.service_sub i {
    color: #DD7016;
    font-size: large;
}

.service_sub p {
    font-weight: 600;
    color: #000;
}


/************ OUR PROJECT ***************/

.swiper-pagination-bullet {
    width: 15px !important;
    height: 15px !important;
    border: 1px solid var(--white);
    border-radius: 50%;
    background: var(--white);
}

.swiper-pagination-bullet-active {
    color: #fff;
    background: var(--orange) !important;
}

.our_project .swiper {
    background-color: #FFF5E5;
}

.our_project .show_all {
    background-color: #fff;
    border-radius: 5px;
    font-size: 14px;
    padding: 10px 20px;
    border: 1px solid;
    color: var(--text-color);
}

.projects {
    background-color: #FFF5E5;
    padding-top: 25px;
    padding-bottom: 125px;
}

.projects h5 {
    font-size: 16px;
    font-weight: 600;
    color: var(--black);
}

.project-item {
    height: 450px !important;
    margin-bottom: 20px;
    position: relative;
}

.project-item .view_project,
.project-item-1 .view_project {
    color: #DD7016;
    font-size: 15px;
    position: absolute;
    bottom: 20px;
    left: 0px;
    right: 0px;
    text-align: center;
    font-weight: 600;
}

.project-item img {
    background-color: #00085C;
    margin-bottom: -50px;
    height: 80px;
}

.project_number {
    padding: 5px;
    width: 35px;
    height: 35px;
    color: #000;
    margin: 10px auto;
    margin-bottom: 20px;
    border-radius: 50%;
    border: 1px dashed #DD7016;
}

.project-item .detail {
    padding-top: 70px !important;
    height: 400px;
    padding: 25px;
    font-size: 15px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    background-color: var(--white);
}

.detail a {
    color: #000;
}

.detail a:hover,
.detail a:focus {
    color: var(--first-color);
}

.divider {
    height: 2px;
    width: 70px;
    background-color: #DD7016;
    margin: 10px auto;
}


/**********/

.projects-1 {
    background-color: #FFF5E5;
    padding-top: 25px;
}

.projects-1 h5 {
    font-size: 16px;
    font-weight: 600;
    color: var(--black);
}

.project-item-1 {
    height: 450px !important;
    margin-bottom: 20px;
    position: relative;
}

.project-item-1 img {
    background-color: #00085C;
    margin-bottom: -50px;
    height: 80px;
}

.project_number-1 {
    padding: 5px;
    width: 35px;
    height: 35px;
    color: #000;
    margin: 10px auto;
    margin-bottom: 20px;
    border-radius: 50%;
    border: 1px dashed #DD7016;
}

.project-item-1 .detail {
    padding-top: 70px !important;
    height: 400px;
    padding: 25px;
    font-size: 15px;
    background-color: var(--white);
}

.single_project {
    background-color: #FFF5E5;
}


/************ OUR DIRECTORS ***************/

.our_directors {
    background-color: #00085C;
    padding: 50px !important;
    color: var(--white);
    margin-top: -100px !important;
}

.director {
    text-align: center;
    position: relative;
}

.director_icon {
    background-color: #DD7016;
    padding: 5px 10px;
    font-size: 20px;
    float: right;
    display: none;
    color: var(--white);
}

.director h4 {
    font-size: 18px;
}

.director img {
    width: 100%;
    margin: 25px 0px;
    background: var(--white);
    padding: 20px;
    object-fit: contain;
    height: 250px;
}

.our_directors h6 {
    color: #ddd;
    font-size: 14px;
}

.section_title {
    color: #555;
    font-size: 18px;
}


/*******/

.our_directors-1 {
    background-color: #FFF;
    padding: 30px !important;
    margin-bottom: 10px !important;
    margin-bottom: 0px;
    border-bottom: 1px solid var(--black);
    color: var(--black);
}

.team_member {
    text-align: center;
}

.team_member img {
    height: 250px;
    width: 100%;
    margin: 10px 0px;
    border-radius: 10px;
    object-fit: cover;
}


/************** CONTACT *************/

.contact {
    padding-top: 10px;
}

.contacts {
    padding: 30px;
    background-color: var(--black-blue);
    color: var(--white);
    margin-top: 0px;
}

.contact__information {
    display: flex;
    margin-bottom: var(--mb-2);
}

.contact__icon {
    font-size: 1.5rem;
    color: var(--white);
    margin-right: var(--mb-0-75);
}

.contact__title {
    font-size: 20px;
    font-weight: var(--font-medium);
    color: var(--white);
}

.contact__subtitle {
    font-size: 14px;
    color: var(--white);
}

input.form-control-,
select.form-control-,
select.form-control- {
    border: 0.05px solid #ccc;
    width: 100%;
    padding: 5px;
    height: 35px;
    border-radius: 2px;
    outline-color: var(--input-color);
}

input.form-control-:focus,
select.form-control-:focus,
textarea.form-control-:focus {
    border: 0px;
    padding: 8px;
    outline-color: var(--first-color);
}

textarea.form-control- {
    border: 0.05px solid #ccc;
    width: 100%;
    padding: 8px;
    border-radius: 2px;
    outline-color: var(--input-color);
}


/********************* ABOUT **********************/

.about {
    background-color: var(--body-color);
    padding-top: 10px;
}

.about_items {
    border-radius: 10px;
    margin: 20px 0px;
    border-radius: 10px;
    background-color: var(--light);
}

.about_item {
    padding: 5px 0px;
    text-align: center;
    color: black;
    cursor: pointer;
    border-radius: 10px;
}

.about_item:hover,
.about_item:focus,
.about_item.active {
    padding: 5px 0px;
    text-align: center;
    color: white;
    border-radius: 0px 10px 10px 0px;
    background-color: var(--first-color);
}

.about_part {
    margin-top: 30px;
}

.about_part h3 {
    font-size: var(--h2-font-size);
    color: var(--black);
    text-align: center;
    margin: 25px 0px;
}

.about_part p {
    color: var(--text-color);
    font-size: 16px;
    text-align: justify;
    line-height: 30px;
}

.about_vision {
    padding: 80px 0px;
}

.trophee_icon {
    font-size: 40px;
    display: none;
    color: var(--white);
}

.trophee_item {
    background-color: var(--white);
    margin-top: 20px;
    padding: 15px;
    padding: 40px 0px;
    padding-bottom: 60px;
    border-radius: 5px;
    border: 2px solid var(--light-hight);
    color: var(--black);
}


/************** PATNERS ************/

.our_patners {
    padding: 50px 0px;
}

.our_patners img {
    height: 60px;
}

.patner {
    text-align: center;
}


/**************** FOOTER *******************/

.footer {
    background-color: var(--black-blue);
    padding: 20px 0px;
    color: #eef;
    text-align: justify;
}

.greenLogo {
    max-height: 120px;
}

.footer p {
    font-size: 14px;
    line-height: 28px;
    padding-right: 0px;
}

.footer dl dt {
    color: var(--white);
    font-size: 15px;
}

.footer dl dd,
.footer dl dd a {
    color: var(--white);
    font-size: 13px;
    margin-top: 10px;
}

.footer dl dd a:hover,
.footer dl dd a:focus {
    color: var(--text-color);
}

@media (max-width: 1120px) {
    #siteName {
        font-size: 14px !important;
    }
    .nav_link {
        font-size: 12px !important;
    }
}

@media (max-width: 500px) {
    .header {
        height: 300px;
        padding: 0px;
    }
    .slide_one {
        padding: 0px 30px;
    }
    .slide_two {
        padding: 0px 30px;
    }
    .slide_three {
        padding: 0px 30px;
    }
    .our_company_content {
        padding: 10px;
        line-height: 30px;
    }
    .our_company_content h6 {
        line-height: 30px;
    }
    .entreprise_dot {
        height: 55px;
    }
    .entreprise_image {
        height: 200px;
        object-fit: cover;
        width: 100%;
    }
    .entreprise_dot_detail {
        padding: 15px 20px;
        text-align: justify;
    }
    .member_link {
        position: static;
    }
    .swiper-button-next,
    .swiper-button-prev,
    .swiper-button-next::after,
    .swiper-button-prev::after {
        display: none;
        background-color: transparent;
    }
}


/************* MY OWN CONTAINER ************/

.container,
.containe {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 768px) {
    .container,
    .containe {
        width: 100%;
    }
}

@media (min-width: 992px) {
    .container,
    .containe {
        width: 100%;
    }
}

@media (min-width: 1200px) {
    .container,
    .containe {
        width: 1170px;
    }
}

.floating {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    padding-top: 8px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
}

a.floating:hover,
a.floating:focus {
    color: #ddd;
    opacity: 0.8;
}

.news {
    background-color: #999;
    margin: 0px;
}

.postTitle {
    font-size: large;
    color: var(--black);
    font-weight: bold;
}

.postTitle:hover,
.postTitle:focus {
    font-size: large;
    color: var(--first-color);
    font-weight: bold;
}


/************** LANGUAGE ***************/

.language_img {
    height: 65px;
    width: 65px;
    margin-top: 10px;
}